import { AuthenticatedRoute } from '@/components/auth';
import Home from '@/components/home/index';
import { initializeApollo, APOLLO_STATE_PROP_NAME } from '@/lib/apollo';

import { ME, GET_STORE, OFFERS_LIST, FEATURES, COLLECTIONS } from '@/query';

const Page = () => (
  <AuthenticatedRoute>
    <Home />
  </AuthenticatedRoute>
);

export async function getServerSideProps({ query }) {
  const {
    shop: domain,
    page = '1',
    type: types,
    status,
    search: queryValue,
    sort: sortBy = 'created_at desc',
  } = query;

  if (!domain) {
    return { props: {} };
  }

  try {
    const apolloClient = initializeApollo();
    const currentPage = parseInt(page, 10);
    const parsedTypes = types ? types.split(',') : null;
    const parsedStatus =
      status === 'true' ? true : status === 'false' ? false : null;

    await Promise.all([
      apolloClient.query({
        query: GET_STORE,
        context: { store: { domain } },
      }),
      apolloClient.query({
        query: ME,
        context: { store: { domain } },
      }),
      apolloClient.query({
        query: OFFERS_LIST,
        variables: {
          offset: (currentPage - 1) * 20,
          limit: 20,
          input: {
            types: parsedTypes,
            active: parsedStatus,
            query: queryValue || '',
            sortBy,
          },
        },
        context: { store: { domain } },
      }),
      apolloClient.query({
        query: FEATURES,
        context: { store: { domain } },
      }),
      apolloClient.query({
        query: COLLECTIONS,
        context: { store: { domain } },
      }),
    ]);

    // Return initial props along with Apollo state
    return {
      props: {
        [APOLLO_STATE_PROP_NAME]: apolloClient.cache.extract(),
      },
    };
  } catch (error) {
    console.error('Server error:', error);
    return {
      props: {
        error: 'Server error occurred',
      },
    };
  }
}

export default Page;
