import { useState, useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import styled from 'styled-components';
import {
  IndexTable,
  Badge,
  Text,
  Button,
  Popover,
  ActionList,
  InlineStack,
} from '@shopify/polaris';
import { HorizontalDotsMinor, ViewMinor } from '@shopify/polaris-icons';
import { useStore, useToaster, useOfferPreview } from '@/hooks';
import { UPDATE_OFFER, DELETE_OFFER, CREATE_OFFER } from '@/query';
import { renderMoney } from '@/util/money';
import ProductCellContent from './product-cell';

const types = {
  bundle: {
    name: 'Bundle',
    path: 'bundles',
  },
  addon: {
    name: 'Addon',
    path: 'addons',
  },
  recommendation: {
    name: 'Recommendations',
    path: 'recommendations',
  },
  'post-purchase': {
    name: 'Post-purchase',
    path: 'post-purchase',
  },
  volume: {
    name: 'Volume discount',
    path: 'volume',
    code: 'vd',
  },
  'popup-upsell': {
    name: 'Popup upsell',
    path: 'popup-upsell',
    code: 'popup-upsell',
  },
  'popup-cross-sell': {
    name: 'Popup cross-sell',
    path: 'popup-cross-sell',
    code: 'popup-cross-sell',
  },
  'cart-recommendation': {
    name: 'In-cart Rec',
    path: 'cart-recommendation',
    code: 'cart-recommendation',
  },
  'checkout-upsell': {
    name: 'Checkout upsell',
    path: 'checkout-upsell',
    code: 'checkout-upsell',
  },
  'free-gift': {
    name: 'Free gift',
    path: 'free-gift',
    code: 'free-gift',
  },
};

const Row = ({ offer, index, collections }) => {
  const { id, name, data, type, offerProducts, triggerProducts, active } =
    offer;
  const [actionsActive, setActionsActive] = useState(false);
  const toast = useToaster();
  const { store } = useStore();
  const { preview } = useOfferPreview(offer);
  const [updateOffer] = useMutation(UPDATE_OFFER);
  const [deleteOffer] = useMutation(DELETE_OFFER, {
    update(cache, { data: { offerDelete } }) {
      cache.modify({
        fields: {
          offersList(existing = {}, { readField }) {
            const { offers = [], ...rest } = existing;
            return {
              ...rest,
              offers: offers.filter(
                (ref) => !offerDelete.deletedIds.includes(readField('id', ref))
              ),
              count: (existing.count || 0) - 1,
            };
          },
        },
      });
    },
  });
  const [createOffer] = useMutation(CREATE_OFFER);

  const toggleActions = useCallback(
    () => setActionsActive((actionsActive) => !actionsActive),
    []
  );

  const toggleStatus = useCallback(() => {
    updateOffer({
      variables: {
        input: {
          id,
          active: !active,
        },
      },
      optimisticResponse: {
        offerUpdate: {
          ...offer,
          active: !active,
          __typename: 'Offer',
        },
      },
    }).then(() => toast({ content: 'Offer updated' }));
    toggleActions();
  }, [active, offer]);

  const copyIdToClipboard = () => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast({ content: 'Widget ID copied to clipboard' });
      })
      .catch((error) => {
        toast({ content: 'Failed to copy' });
      });
    toggleActions();
  };

  const copySnippetToClipboard = () => {
    const code = types[type].code || type;
    const isSingleProduct =
      data?.trigger?.type === 'product' && data?.trigger?.value?.length === 1;
    const html = `<div id="ast-${code}-container" data-block-widget-id="${id}"></div>`;

    navigator.clipboard
      .writeText(html)
      .then(() => {
        toast({ content: 'Snippet copied to clipboard' });
      })
      .catch((error) => {
        toast({ content: 'Failed to copy' });
      });
    toggleActions();
  };

  const duplicateOffer = useCallback(async () => {
    await createOffer({
      variables: {
        input: {
          type,
          data: {
            ...data,
            name: data.name ? `${data.name} (copy)` : '',
          },
          active: false,
        },
      },
      update(cache, { data: { offerCreate } }) {
        cache.modify({
          fields: {
            offersList(existing = {}) {
              const { offers = [], ...rest } = existing;
              const newOfferRef = cache.writeFragment({
                data: offerCreate,
                fragment: gql`
                  fragment NewOffer on Offer {
                    id
                    name
                    type
                    active
                    data
                    offerProducts {
                      id
                      attributes {
                        title
                        image
                      }
                    }
                    triggerProducts {
                      id
                      attributes {
                        title
                        image
                        handle
                        onlineStoreUrl
                      }
                    }
                    impressions
                    orders
                    revenue
                    createdAt
                  }
                `,
              });
              return {
                ...rest,
                offers: [newOfferRef, ...offers],
                count: (existing.count || 0) + 1,
              };
            },
          },
        });
      },
    });
    toggleActions();
  }, [createOffer, data, toggleActions, type]);

  const prePurchaseOptions =
    type !== 'post-purchase'
      ? [
          {
            content: 'Preview',
            // icon: ViewMinor,
            onAction: () => {
              preview();
              toggleActions();
            },
          },
          {
            content: 'Copy ID',
            onAction: copyIdToClipboard,
          },
          {
            content: 'Copy snippet',
            onAction: copySnippetToClipboard,
          },
        ]
      : [];

  const actions = [
    {
      content: 'Edit',
      // icon: EditMinor,
      url: `/edit/${id}`,
    },
    {
      content: active ? 'Disable' : 'Enable',
      // icon: ToggleMinor,
      onAction: toggleStatus,
    },
    {
      content: 'Duplicate',
      onAction: duplicateOffer,
    },
    ...prePurchaseOptions,
    {
      content: 'Delete',
      destructive: true,
      // icon: DeleteMinor,
      onAction: () => {
        deleteOffer({
          variables: { id },
        }).then(() => toast({ content: 'Offer deleted' }));
        toggleActions();
      },
    },
  ];

  const actionsMarkup = (
    <Actions>
      <InlineStack gap="100" align="center" wrap={false}>
        <div className="widget-preview">
          <Button
            icon={ViewMinor}
            accessibilityLabel="Preview widget"
            variant="plain"
            size="medium"
            onClick={preview}
          />
        </div>
        <Popover
          active={actionsActive}
          activator={
            <Button
              icon={HorizontalDotsMinor}
              // size="medium"
              onClick={toggleActions}
              variant="plain"
            />
          }
          // autofocusTarget="first-node"
          onClose={toggleActions}
        >
          <ActionList actionRole="menuitem" items={actions} />
        </Popover>
      </InlineStack>
    </Actions>
  );

  const createdAt = new Date(offer.createdAt);
  const createdAtDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(createdAt);

  return (
    <IndexTable.Row id={id} key={id} position={index}>
      <Name>
        <Text as="strong">{name}</Text>
      </Name>
      <ProductCell>
        <ProductCellContent
          data={offer.data.trigger}
          products={offer.triggerProducts}
          collections={collections}
        />
      </ProductCell>
      <ProductCell>
        <ProductCellContent
          data={offer.data.offer}
          products={offer.offerProducts}
          collections={collections}
        />
      </ProductCell>
      <NarrowCell>{offer.impressions || '-'}</NarrowCell>
      <NarrowCell>{offer.orders || '-'}</NarrowCell>
      <NarrowCell>
        {offer.revenue
          ? renderMoney(offer.revenue, store?.currency || 'USD')
          : '-'}
      </NarrowCell>
      <NarrowCell>
        <Badge>
          <p style={{ textTransform: 'capitalize' }}>{types[type].name}</p>
        </Badge>
      </NarrowCell>
      <IndexTable.Cell>
        <Text>{createdAtDate}</Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badge tone={active ? 'success' : 'attention'}>
          {active ? 'Active' : 'Disabled'}
        </Badge>
      </IndexTable.Cell>

      {actionsMarkup}
    </IndexTable.Row>
  );
};

const Name = styled(IndexTable.Cell)`
  min-width: 200px;
  max-width: 250px;
  white-space: normal;
  width: auto;
  /* padding-right: 24px !important; */
`;

const ProductCell = styled(IndexTable.Cell)`
  max-width: 20vw;
  min-width: 12.5rem;
`;

const NarrowCell = styled(IndexTable.Cell)`
  max-width: 120px;
`;

const Actions = styled(IndexTable.Cell)`
  /* width: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Row;
