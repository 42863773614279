import { useRouter } from 'next/router';
import {
  Page,
  Divider,
  InlineStack,
  Text,
  Link,
  Box,
  Card,
} from '@shopify/polaris';
import dayjs from 'dayjs';
import {
  useQueryState,
  parseAsArrayOf,
  parseAsString,
  parseAsInteger,
  parseAsBoolean,
} from 'nuqs';
import { OFFERS_LIST } from '@/query';
import { usePaginatedQuery, useSupport } from '@/hooks';
import LangProvider from '@/providers/language';
import { FlowbaseBanner } from './flowbase-banner';
import Offers from './offers';
import OfferFilters from './offers/filters';

// import SubscriptionAlert from './subscription-alert';

const analyticsFilters = {
  startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
};

const Home = ({
  initialPage = 1,
  initialType = null,
  initialStatus = null,
  initialSearch = null,
  initialSort = 'created_at desc',
  error = null,
}) => {
  const [types, setTypes] = useQueryState('type', {
    parse: parseAsArrayOf(parseAsString),
    defaultValue: initialType,
  });
  const [status, setStatus] = useQueryState('status', {
    parse: parseAsBoolean,
    defaultValue: initialStatus,
  });
  const [queryValue, setQueryValue] = useQueryState('search', {
    parse: parseAsString,
    defaultValue: initialSearch,
  });
  const [sortBy, setSortBy] = useQueryState('sort', {
    parse: parseAsArrayOf(parseAsString),
    defaultValue: [initialSort],
  });
  const [page, setPage] = useQueryState('page', {
    parse: parseAsInteger,
    defaultValue: initialPage,
  });
  const router = useRouter();
  const { startContactSupport } = useSupport();

  const [{ loading, error: queryError, data }, pagination] = usePaginatedQuery(
    OFFERS_LIST,
    {
      variables: {
        input: {
          query: queryValue || '',
          types: types && types.length > 0 ? types : null,
          active: status,
          sortBy: sortBy[0],
        },
      },
      onCompleted: (res) => {
        const { offers } = res?.offersList || {};
        if (offers?.length === 0 && !queryValue && page === 1) {
          router.push('/create?first=true', { scroll: false });
        }
      },
    },
    20, // limit
    null, // fixedCount
    page, // initialPage
    setPage // onPageChange to sync URL query params
  );

  if (error || queryError) {
    return (
      <Page>
        <Card>
          <Box padding="400">
            <InlineStack align="center">
              <Text tone="critical">
                {error || queryError?.message || 'An error occurred'}. Please
                try refreshing the page.
              </Text>
            </InlineStack>
          </Box>
        </Card>
      </Page>
    );
  }

  const { offers } = data?.offersList || {};

  return (
    <LangProvider>
      <FlowbaseBanner />
      <Page
        fullWidth
        title="Widgets"
        primaryAction={{
          content: 'Create widget',
          url: '/create',
        }}
      >
        <Card padding="0">
          <OfferFilters
            type={types}
            setType={setTypes}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            status={status}
            setStatus={setStatus}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          <Offers offers={offers || []} loading={loading} />
          {pagination?.active && (
            <Box>
              <Divider />
              <InlineStack align="center">
                <Box padding="400">{pagination?.paginationMarkup}</Box>
              </InlineStack>
            </Box>
          )}
        </Card>
        <div style={{ marginTop: '12px' }}>
          <InlineStack align="center">
            <Text variant="bodyMd" as="p" tone="subdued">
              To install widgets on your store, please refer to{' '}
              <Link
                monochrome
                url="https://help.assortion.com/en/articles/7208889-installing-widgets-to-your-store"
                external
              >
                this guide
              </Link>{' '}
              or{' '}
              <Link monochrome onClick={startContactSupport}>
                contact us
              </Link>{' '}
              for assistance
            </Text>
          </InlineStack>
        </div>
        {/* <Support /> */}
      </Page>
    </LangProvider>
  );
};

export default Home;
